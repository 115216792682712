<template>
<div id="songs-playlist-profile">
    <div class="d-f jc-c big-m-b big-m-t">
        <!-- <h1 class="text-link">PLAY</h1> -->
        <a v-if="playlist.songs.length > 0"
            class="tt-up"
            @click="$emit('play-select-song', {playerPosition: playlistPosition, trackId: 0})">
            <h2>Play</h2>
        </a>
    </div>
    <SongBlockProfile
        v-for="(song, key) in playlist.songs" 
        :song="song" 
        :count="key" 
        :key="song" 
        :playlist-position="playlistPosition" 
        :editButtons="editButtons"
        @play-item="$emit('play-select-song', {playerPosition: playlistPosition, trackId: key})">
    </SongBlockProfile>
</div>
</template>

<script>
import SongBlockProfile from '@/components/profile/SongBlockProfile'

export default {
    name: 'SongsPlaylistProfile',
    components: {
      SongBlockProfile
    },
    props: ['playlist', 'playlistPosition', 'editButtons']
}
</script>

<style lang="scss" scoped>
#songs-playlist-profile {
    margin-bottom: 50vh;
}
</style>
