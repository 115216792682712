<template>
<div id="song-block-profile" class="big-m-b">
    <div 
    class="hover"
    :class="(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'playlist-track active-color' : 'playlist-track inactive-color'"
    @mouseenter="toggleHoverFunc" 
    @mouseleave="toggleHoverFunc"
    @click="handleClick">
        <!-- <span class="ws-pre">{{count+1}}. </span> -->
        <div 
        class="song-cover" 
        :class="{ hoverbr : toggleHover }"
        v-if="song.cover">
            <img :class="{ hovermask : toggleHover, spinCoverAni : spinCover }" :src="song.cover" alt="">
            <div class="hover-box" v-show="toggleHover">
                <!-- <h1>PLAY</h1> -->
            </div>
        </div>
        <div class="d-f w-100 jc-c p bs-bb">
            <a :class="{ underline : toggleHover }"><p>{{song.title}}</p></a>
        </div>
        <!-- <span class="ws-pre"> - </span> -->
        <!-- <span>{{song.artist}}</span>  -->
        {{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? '' : ''}} 
        <!-- <span :style="'background: rgba(0,255,0, .2); width: '+(currentTrackTime/currentTrackDuration * 100)+'%;'" :class="(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-p-0 tw-m-0 tw-h-full tw-w-full tw-z-30 tw-bg-green-100 tw-h-full' : 'tw-hidden'"></span> -->
        <span
        v-if="((activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition)) && playerIsLoading">
            <BufferingIcon animate="beat" class="tw-text-white tw-cursor-pointer" w="30" h="30" />
        </span> 
        <div v-if="editButtons">
            <span class="ws-pre"> - </span>
            <a @click="deleteSong(song.songUid)">Delete</a>
        </div>
    </div>
</div>
</template>

<script>
import PlayerMixin from '@/mixins/PlayerMixin'

import BufferingIcon from 'vue-ionicons/dist/ios-play-circle'

export default {
    name: 'SongBlockProfile',
    props: ['song', 'count', 'playlistPosition', 'editButtons'],
    components: {BufferingIcon},
    mixins: [PlayerMixin],
    data() {
        return {
            toggleHover: false,
            spinCover: false
        }
    },
    methods: {
        toggleHoverFunc() {
            this.toggleHover = !this.toggleHover;
        },
        spinCoverFunc() {
            this.spinCover = true;
            setTimeout(() => {
                this.spinCover = false
            }, 1500)
        },
        handleClick() {
            this.$emit('play-item');
            this.spinCoverFunc();
        }
    }
}
</script>

<style lang="scss" scoped>
// #song-block-profile {
//     /* blink ani */
//     //OLD BELOW
//     // .playlist-track {
//     //     position: relative;
//     //     display: flex;
//     // .track-duration {
//     //     position: absolute;
//     //     top: 0;
//     //     bottom: 0;
//     //     left: 0;
//     //     right: 0;
//     //     z-index: 40;
//     // }
//     // .track-duration-hide {
//     //     display: none;
//     // }
//     // .buffering-icon {
//     //     position: absolute;
//     //     right: 0;
//     // }
//     // }
//     // .active-color {
//     // background: rgba(255,0,0, .2);
//     // }
// }

</style>
